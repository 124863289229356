import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import './App.css';
import Home from "./pages/Home";
import Events from "./pages/Events";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      {/* <Route path="/" element={<LayoutContext />}> */}
        <Route exact path={`${process.env.PUBLIC_URL + "/"}`} element={<Home />} />
        <Route exact path={`${process.env.PUBLIC_URL + "/events"}`} element={<Events />} />


        <Route path="*" element={<Navigate to="/" replace />} />
      {/* </Route> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
