import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo/logo.svg";
import ThanksImage from "../assets/img/thanks.jpg";
import GraphIcon from "../assets/img/animated-icon/graph.png";
import StarIcon from "../assets/img/animated-icon/star-icon.png";
import StarImage from "../assets/img/animated-icon/star.png";
import TeamImage from "../assets/img/hero/team.jpg";
import Customer1 from "../assets/img/hero/customer-1.jpg";
import Customer2 from "../assets/img/hero/customer-2.jpg";
import ComputerGesture from "../assets/img/hero/customer-service-cute-guy-grey-suit-with-computer-headset-smiling-showing-good-gesture.jpg";
import ComputerWhileC from "../assets/img/hero/happy-business-couple-working-computer-while-sitting-cafe.jpg";

import modernEquippedComputerLab from "../assets/img/hero/modern-equipped-computer-lab.jpg";
import graphIcon from "../assets/img/icon/graph.svg";
import softwareIcon from "../assets/img/icon/software.svg";
import productIcon from "../assets/img/icon/product.svg";
import uiIcon from "../assets/img/icon/ui.svg";
import brandIcon from "../assets/img/icon/brand.svg";
import marketingIcon from "../assets/img/icon/marketing.svg";
import graphicIcon from "../assets/img/icon/graphic.svg";
import uiuxIcon from "../assets/img/icon/uiux.svg";
import designTeam from "../assets/img/about/design-team.jpg";
import researchTeam from "../assets/img/about/reasearch-team.jpg";
import marketingTeam from "../assets/img/about/marketing-team.jpg";
import developerTeam from "../assets/img/about/developer-team.jpg";

import eLearningImage from "../assets/img/project/e-learning.jpg";
import futureDesignImage from "../assets/img/project/future-design.jpg";
import dashboardDesignImage from "../assets/img/project/dasboard-design.jpg";
import ecommerceMobileImage from "../assets/img/project/eccomerce-mobile.jpg";
import sassLandingImage from "../assets/img/project/sass-landing.jpg";

import team1 from "../assets/img/team/team-1.png";
import team3 from "../assets/img/team/team-3.png";
import team4 from "../assets/img/team/team-4.png";
import teamMember4 from "../assets/img/team/team-member-4.png";
import testimonialTwo from "../assets/img/testimonial/testimonial-two.jpg";
import quoteTwo from "../assets/img/animated-icon/quote-two.png";
import author1 from "../assets/img/testimonial/author-1.png";
import author2 from "../assets/img/testimonial/author-2.png";
import author3 from "../assets/img/testimonial/author-3.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog1_2 from "../assets/img/blog/blog-1-2.jpg";
import blog1_3 from "../assets/img/blog/blog-1-3.jpg";
import author1Img from "../assets/img/author/author-1.jpg";
import author2Img from "../assets/img/author/author-2.jpg";
import author3Img from "../assets/img/author/author-3.jpg";
import footerLogo from "../assets/img/logo/footer-logo.svg";
import sponsor1 from "../assets/img/clients/sponsor-1.png";
import sponsor2 from "../assets/img/clients/sponsor-2.png";
import sponsor3 from "../assets/img/clients/sponsor-3.png";
import sponsor4 from "../assets/img/clients/sponsor-4.png";
import sponsor5 from "../assets/img/clients/sponsor-5.png";
import line3 from "../assets/img/animated-icon/line-3.png";
import about3 from "../assets/img/about/about-3.png";
import about31 from "../assets/img/about/about-3-1.png";
import down1 from "../assets/img/down-1.png";
import down2 from "../assets/img/down-2.png";
import ammarSign from "../assets/img/signature 1.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "lodash";
import ReactSelect from "react-select";

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [fullName, setfullName] = useState([]);
  const [skype, setskype] = useState([]);
  const [phone, setphone] = useState([]);
  const [email, setemail] = useState([]);
  const [message, setmessage] = useState([]);
  const [lang, setlang] = useState({ value: "EN", label: "EN" });

  const [menu, setmenu] = useState(false);
  // document.documentElement.dir = "rtl" // for rtl

  useEffect(() => {
    axios.get("https://hma-back-xg3n.onrender.com/api/statistic/AllData").then((response) => {
      if (response?.data) {
        setData(response?.data);
      }
    });
  }, []);

  const addContact = async () => {
    await axios
      .post("https://hma-back-xg3n.onrender.com/api/contact", {
        fullName,
        skype,
        phone,
        email,
        message,
      })
      .then((response) => {})
      .catch(function (err) {});
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <nav>
            <div class="logo">
              {" "}
              <img src={Logo} alt="Insoand Logo" />
            </div>
            <input type="checkbox" id="checkbox" checked={menu} onChange={(e) => setmenu(e.target.checked)} />
            <label for="checkbox" id="icon">
              <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </label>
            <ul>
              <li>
                <a href="#homeid" onClick={(e) => setmenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="#aboutid" onClick={(e) => setmenu(false)}>
                  About
                </a>
              </li>
              <li>
                <a href="#projectsid" onClick={(e) => setmenu(false)}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#Servicesid" onClick={(e) => setmenu(false)}>
                  Services
                </a>
              </li>
              <li>
                <a href="#teamid" onClick={(e) => setmenu(false)}>
                  Team
                </a>
              </li>
              <li>
                <a href="#contactid" onClick={(e) => setmenu(false)}>
                  Contact
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    navigate("/events");
                    setmenu(false);
                  }}
                >
                  HMA Events
                </a>
              </li>
            </ul>
            <ReactSelect
              className="selectLang"
              isSearchable={false}
              value={lang}
              onChange={(val) => setlang(val)}
              theme={(theme) => ({
                ...theme,
                //borderRadius: 0,
                borderStyle: "none",
                boxShadow: "none",
                colors: {
                  ...theme.colors,
                  /*
                   * control/backgroundColor
                   * menu/backgroundColor
                   * option/color(selected)
                   */
                  neutral0: "#0d0c1c",
                  neutral30: "#0d0c1c", //control/borderColor(focused)
                  neutral10: "#fff",
                  neutral50: "#000", // pacleholder color
                  neutral80: "#fff", //input color
                  primary25: "#c1c1c1", //option bg color focued
                  primary: "#238aec", //option bg color selected
                  primary50: "#238aec", // option bg color active(enavled or available)
                },
              })}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  // This line disable the blue border
                  boxShadow: "none",
                }),

                option: (base) => ({
                  ...base,
                  color: "#fff",
                }),
              }}
              options={[
                { value: "EN", label: "EN" },
                { value: "FR", label: "FR" },
                { value: "AR", label: "AR" },
              ]}
            />
          </nav>
        </div>
      </header>
      {/* header menu end */}
      {/* hero area */}
      <div className="hero pt-100 pb-100 hero-walpaper" id="homeid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero__text">
                {/* <div className="hero__vector">
                  <img src={GraphIcon} alt="graph" />
                  <img src={StarIcon} alt="graph" />
                </div> */}
                <div className="hero__text--content relative">
                  <h4>CREATIVITY • INNOVATION • FUTURE</h4> <br />
                  {/* <img src={StarImage} alt="graph" /> */}
                  <h3>
                    LEADING THE <span>INNOVATION</span>
                  </h3>
                  {/* <div className="hero__video" data-sal="slide-left" data-sal-delay={400} data-sal-easing="ease-out-back">
                    <div className="video-img">
                      <img src={TeamImage} alt="" />
                      <div className="video-play" data-video-id="u31qwQUeGuM">
                        <i className="fa-solid fa-play" />
                      </div>
                    </div>
                  </div> */}
                  <div className="w-25 description-hero">
                    <span className="text-break">
                      HMATechs is a registered and goverment-tusted IT comspanany with all required licenses and certificates
                    </span>
                  </div>
                  <div className="hero__button">
                    <a href="#" className="rounded-btn-relative">
                      Get in Touch{" "}
                      {/* <span>
                      <i className="fa-sharp fa-light fa-arrow-right-long" />
                    </span> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* hero area end */}
      {/* about */}
      <div className="about style-two fix pt-110 pb-110" id="aboutid">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="about__images position-relative">
                <div className="animated__image" key="line3">
                  <img src={line3} alt="" />
                </div>
                <div className="img-with-text" key="about3">
                  <img src={isEmpty(data?.About) ? about3 : data?.About?.image1} alt="" />
                  <div className="expeert">
                    <span>15</span> Years of Experience
                  </div>
                </div>
                <div className="img-notext" key="about31">
                  <img src={isEmpty(data?.About) ? about31 : data?.About?.image2} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <h3>About Us</h3>
              <p>
                {isEmpty(data?.About)
                  ? `HMATechs was established on January 1st, 2009, in the Hashemite Kingdom of Jordan and Tunisia, focusing on providing a one-of-kind services package in Middle East & Africa region (MEA).
                  HMATechs official work in GCC started in 2015 by providing its clients with Niche (ICT solutions, and consulting services and sale of software).
                  In 2021 mission has been expanded in Qatar to be the Hub and to harness the Emerging-Technology to Revolutionize the Innovational output for enterprises and startups in the region.
                  HMATechs today has a business partnership with tech solutions providers in the global market to help its clients in their Digital Transformational and business productivity Enhancement goals from day one.`
                  : data?.About?.description}
              </p>

              <a href="#Servicesid" className="mt-30 button__two">
                Learn More About
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* about us end */}

      {/* service */}
      <section className="service pt-100 pb-10" id="Servicesid">
        <div className="container">
          <div className="section">
            <div className="section__content">
              <h6 className="section__sub">Which Services We Provide</h6>
              <h3 className="section__title">
                Modern &amp; Intuitive web <strong>Solutions</strong> Agency
              </h3>
            </div>
            {/* <div className="view__all">
              <a href="service.html">View All Services</a>
            </div> */}
          </div>
          <div className="row">
            {data?.Service?.map((e) => {
              return (
                <div className="col-lg-4 col-xl-4 col-md-4">
                  <div className="service__single">
                    <div className="service__single--box">
                      <div className="icon">
                        <img src={softwareIcon} alt="" />
                      </div>
                      <div className="service__single--box-meta">
                        <div className="meta-text">
                          <a href="#">{e?.title}</a>
                          <span>{e?.description.slice(0, 50)} ...</span>
                        </div>
                        <div className="meta-linkbtn">
                          <a href="#" className="link-btn">
                            <i className="fa-sharp fa-light fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* service end */}

      {/* work marque */}
      <div className="our-work pt-100 pb-100">
        <div className="our-work__wrapper">
          <div className="our-work__content">
            <div className="single-work-item">
              <span className="title">INNOVATION </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Development</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Marketing</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">AI</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">CREATIVITY</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">INNOVATION </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Development</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Marketing</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">AI</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">CREATIVITY</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">INNOVATION </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Development</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">Marketing</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">AI</span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">CREATIVITY</span>
              <span className="start">*</span>
            </div>
          </div>
        </div>
      </div>
      {/* work marque end */}
      {/* our project */}
      <section className="project" id="projectsid">
        <div className="container">
          <div className="section">
            <div className="section__content">
              <h6 className="section__sub">Latest Project</h6>
              <h3 className="section__title">
                Our Latest Awards Winning <strong>Projects</strong>{" "}
              </h3>
            </div>
            {/* <div className="view__all">
              <a href="portfolio.html">View All Projects</a>
            </div> */}
          </div>
          <div className="row">
            {data?.Project?.map((e) => (
              <div className="col-lg-4 col-md-6">
                <div className="project__single" title="E-learning Website Project">
                  <div className="project__single--box">
                    <img className="img-fluid" src={e?.images[0]} alt="" />
                    <div className="project__meta">
                      <div className="project__meta--info">
                        <span className="project-name">
                          <a href="portfolio-details.html">{e?.name}</a>
                        </span>
                        <span className="project-by">{e?.client}</span>
                      </div>
                      <div className="project__meta--link">
                        <a href="portfolio-details.html" className="link-btn">
                          <i className="fa-sharp fa-light fa-arrow-right-long" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* our project end */}

      <div className="counter-five-area pt-100 mt-50 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="counter-five-content">
                <h3> We Create Websites Business Services , AI , IOT ...</h3>
                <p>Our approach is centered around understanding the unique objectives and challenges of each client.</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five color">
                    <h2>
                      12K <img src={down1} alt="down" />
                    </h2>
                    <span> Happy Customers</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five">
                    <h2>
                      35K <img src={down2} alt="down" />
                    </h2>
                    <span>Success Project</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five ms-100">
                    <h2>
                      150% <img src={down2} alt="down" />
                    </h2>
                    <span>Conversion Rate Increased</span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five ms-100">
                    <h2>
                      50+ <img src={down2} alt="down" />
                    </h2>
                    <span>Awards Wining</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {data?.Teams?.length === 0 ? (
        <div className="row align-items-center p-50 bg-light" id="teamid">
          {/* Image Placeholder */}

          <div className="col-md-4 mr-150">
            <div className="ceo-picture rounded" style={{ width: "100%", paddingTop: "100%" }} />

            <div className="d-flex justify-content-center align-items-center flex-column">
              <img className="image-ceo-sign mt-25" src={ammarSign} alt="" />

              <h2 className="fw-bold fst-italic" style={{ color: "black" }}>
                CEO
              </h2>
            </div>
          </div>

          {/* Text Content */}
          <div className="col-md-6 paragraph-ceo">
            <p className="text-justify" style={{ textAlign: "justify" }}>
            Mohamed AMMAR is a visionary leader and innovator, serving as the
              Founder and CEO of a cutting-edge enterprise dedicated to
              entrepreneurship, innovation management, and driving digital
              transformation. I am passionate about helping startups and
              established companies alike navigate the complexities of cloud
              technology and digital transformation, ensuring they stay
              competitive in today’s fast-paced environment. As a consultant, I
              work closely with organizations to craft tailored strategies that
              foster growth, agility, and efficiency.
              <br />
              In addition to my consulting work, I am an economist and
              sought-after speaker, frequently sharing insights on the evolving
              digital landscape, innovation strategies, and the economic impact
              of technological advancements. My mission is to empower businesses
              to harness the full potential of digital tools and practices,
              making their transformation journey not only seamless but also
              sustainable. With a strong commitment to innovation and
              forward-thinking solutions, I continue to support companies in
              their pursuit of growth and success in an ever-changing digital
              world.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* sponsor */}
      <div className="sponsor style-two">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sponsor__slider">
                <div className="swiper-wrapper">
                  {data?.Parteners?.map((e) => (
                    <div className="swiper-slide" key="sponsor1">
                      <img src={e?.image} alt="" width={150} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* sponsor end */}

      {/* breadcrumb */}
      <div className="breadcrumb pt-80 pb-80" id="contactid">
        <div className="container">
          <div className="breadcrumb__vector">
            <img src="assets/img/animated-icon/graph.png" alt="graph" />
            <img src="assets/img/animated-icon/star-icon.png" alt="graph" />
          </div>
          <div className="breadcrumb__wrapper">
            <div className="breadcrumb__wrapper--text">
              <h5 className="title">Contact Us</h5>
              <p>For businesses with digital products or services, ensuring a visually appealing interface is vital.</p>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb end */}

      {/* contact us */}
      <div className="contact pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-solid fa-phone" />
                  </div>
                  <div className="content">
                    <h6 className="title">Phone</h6>
                    <a href={isEmpty(data?.Configurations) ? "phone" : data?.Configurations?.phone}>
                      {" "}
                      {isEmpty(data?.Configurations) ? "phone" : data?.Configurations?.phone}
                    </a>
                    <a href="mailto:contact@hma.org"> {isEmpty(data?.Configurations) ? "email" : data?.Configurations?.email}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-solid fa-location-dot" />
                  </div>
                  <div className="content">
                    <h6 className="title">Addres</h6>
                    <p> {isEmpty(data?.Configurations) ? "address" : data?.Configurations?.address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-regular fa-clock" />
                  </div>
                  <div className="content">
                    <h6 className="title">Opening Hours</h6>
                    <p>
                      {isEmpty(data?.Configurations) ? "address" : data?.Configurations?.openingDayStart} -{" "}
                      {isEmpty(data?.Configurations) ? "address" : data?.Configurations?.openingDayEnd}
                    </p>
                    <p>
                      {isEmpty(data?.Configurations) ? "address" : data?.Configurations?.openingHourStart} -{" "}
                      {isEmpty(data?.Configurations) ? "address" : data?.Configurations?.openingHourEnd}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="contact__form">
              <div className="comment__template contact__template">
                <div className="comment__template--box input__form">
                  <form
                    // action="mail-sender.php"
                    // method="post"
                    className="contact__php"
                  >
                    <div className="input-group">
                      <div className="single-input">
                        <label htmlFor="name">Full Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          required=""
                          onChange={(e) => {
                            setfullName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Your email"
                          required=""
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="single-input">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          placeholder="Your Number"
                          required=""
                          min={1}
                          onChange={(e) => {
                            setphone(e.target.value);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="skype">Skype Address</label>
                        <input
                          type="text"
                          id="skype"
                          name="skype"
                          placeholder="Your skype"
                          onChange={(e) => {
                            setskype(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="textarea">
                      <label htmlFor="msg">Message</label>
                      <textarea
                        id="msg"
                        name="msg"
                        placeholder="Your Review"
                        required=""
                        defaultValue={""}
                        onChange={(e) => {
                          setmessage(e.target.value);
                        }}
                      />
                    </div>
                    <div className="submit-btn">
                      <button className="main-btn" onClick={(e) => addContact(e)}>
                        Send Message
                      </button>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-success contact__msg" style={{ display: "none" }} role="alert">
                        Your message was sent successfully.
                      </div>
                    </div>
                  </div>
                  {/* end message */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact us end */}
      {/* map */}
      <div className="contact__map">
        <div id="map">
          {/* if you want to use leaflet map just remove <iframe></iframe> */}
          <iframe
            className="google__map w-100"
            src="https://maps.google.com/maps?q=QFC%20tower%2C%20Doha-Qatar&t=m&z=11&output=embed&iwloc=near"
          ></iframe>
        </div>
      </div>
      {/* map end */}

      {/* footer */}
      <footer className="footer pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Contact Us</h5>
                <div className="footer__widget--contact">
                  <ul>
                    <li>
                      <i className="fa-sharp fa-solid fa-phone" />
                      <a href="tel:+8801755202096">{isEmpty(data?.Configurations) ? "phone" : data?.Configurations?.phone}</a>
                    </li>
                    <li>
                      <i className="fa-regular fa-envelope" />
                      <a href="mailto:contact@hma.org">
                        {" "}
                        {isEmpty(data?.Configurations) ? "email" : data?.Configurations?.email}
                      </a>
                    </li>
                  </ul>
                  {/* <div className="footer__social">
                    <a href="#">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Links</h5>
                <div className="footer__widget--link">
                  <ul>
                    <li>
                      <a href="about.html">About Us</a>
                    </li>
                    <li>
                      <a href="portfolio.html">Portfolio</a>
                    </li>
                    <li>
                      <a href="career.html">Careers</a>
                    </li>
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Resources</h5>
                <div className="footer__widget--resource">
                  <ul>
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                    <li>
                      <a href="#">User Guide</a>
                    </li>
                    <li>
                      <a href="#">Realease</a>
                    </li>
                    <li>
                      <a href="#">Video tutorial </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Newsletter</h5>
                <div className="footer__widget--newsletter">
                  <p>
                    If your business hosts events or exhibitions, creative services can assist with event planning, booth design.
                  </p>
                  <form action="#" className="newsletter-box">
                    <input type="email" placeholder="Email Address" />
                    <button type="submit">Subscribe</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer end */}
      {/* footer credit */}
      <div className="footer-credit">
        <div className="container">
          <div className="footer-credit--img">
            <a onClick={() => navigate("/")}>
              <img src={Logo} alt="footer logo" />
            </a>
          </div>
          <div className="footer-credit__wrapper mt-25">
            <div className="copy-right">
              Copyright © {new Date().getFullYear()} - <a href="#"> HMA Techs</a> All Rights Reserved
            </div>
          </div>
        </div>
      </div>
      {/* footer credit end */}
    </>
  );
};

export default Home;
